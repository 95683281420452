'use client';

import { Button } from 'antd';

const CustomButton = ({ text, handler, disabled = false }) => {
    return (
        <Button onClick={handler} disabled={disabled}>
            {text}
        </Button>
    );
};

export default CustomButton;
