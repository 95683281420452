'use client';
import Link from 'next/link';
import CustomButton from '../../Common/Button';

const Error = ({ title, subTitle }) => {
    return (
        <section className='flex h-screen w-screen flex-1 bg-gray-50 p-16 dark:bg-gray-700'>
            <div className='container flex flex-col items-center'>
                <div className='flex max-w-md flex-col gap-8 text-center'>
                    <h2 className='text-6xl font-extrabold'>
                        <span className='sr-only'>{title}</span>
                        {title}
                    </h2>

                    <p className='text-xl text-gray-500 dark:text-gray-300'>{subTitle}</p>
                    <Link href='/'>
                        <CustomButton text='Back to home' handler={null} />
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Error;
